

















































































th, td {
  vertical-align: middle !important;

  &.color {
    padding: 2px;
  }
}
