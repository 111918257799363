






































































































































































































































































































































































































































































































































































































































































































































.btn-group.new-single-file {
  display: flex;

  .btn {
    flex: 1;
  }
}
