


































































































































.content-list {
  th {
    font-weight: 700 !important;
  }

  td.actions>.btn-group {
    min-width: 45px;
  }
}
