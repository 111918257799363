















































































































































a, .file-label {
  cursor: pointer;
}

.file-label {
  display: block;
  margin-bottom: 0;
  font-weight: normal;
}

.file-input {
  display: none;
}
