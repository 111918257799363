
































































































































































.media-manager-upload {
  .file-input {
    position: relative;

    .upload-buttons {
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 100%;
      text-align: right;

      .btn {
        margin-left: 5px;
      }
    }
  }
}
