






































































































.story-medium {
  margin-bottom: 20px;
}

.media-body {
  .input-group {
    margin-bottom: 5px;
  }

  .form-row-for-print, .form-row-for-web {
    margin-left: 10px;
  }
}

.btn-danger.input-group-addon {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger.input-group-addon:hover {
  background-color: #c9302c;
  border-color: #ac2925;
}

.media-left {
  .image {
    position: relative;
    display: block;

    .handle {
      position: absolute;
      display: block;
      text-align: right;
      right: 5px;
      bottom: 5px;
      height: 1em;
      width: 1em;
      font-size: 3em;
      color: white;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      cursor: grab;
    }

    .show-on-hover {
      visibility: hidden;
    }

    &:hover .show-on-hover {
      visibility: visible;
    }
  }
}
