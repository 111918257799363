















































































.vue-labelled-editable-value {
  margin-bottom: 15px;
}
