























































.right-check {
  float: right;
  cursor: pointer;
}
