



















































































































































































.locked-overlay {
  position: relative;

  .show-locked-overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .panel {
    margin-top: 10em;

    .panel-body {
      padding: 4em 6em;
      max-width: 50em;
      text-align: center;
    }
  }
}
