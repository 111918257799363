



























































































































.color-button {
  border: 1px solid #ccc;
}

.color-picker {
  position: absolute;
  z-index: 100;
}
