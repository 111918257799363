























































.nrq-media-filter-bar {
  .input-group {
    .form-control {
      float: none;
    }
  }
}
