











































































































































.tag-rules, .character-rules {
  table {
    thead tr {
      th {
        white-space: nowrap;

        &.original {
          width: 15%;
        }
        &.replacement {
          width: 80%;
        }
        &.actions {
          width: 5%;
        }
      }
    }
  }
}
