














































.list-group-item {
  padding-left: 2em;
}

.right-check {
  float: right;
  cursor: pointer;
}
